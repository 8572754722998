<template>
  <div id="app">
    <!-- <PersistentMessage v-if="hasUpdate" /> -->
    <Navbar v-if="['LoginView'].indexOf($route.name) == -1" />
    <router-view />

    <LoginModal />
    <RegisterModal />
    <RestoreModal />
    <!-- <ChangePasswordModal /> -->
  </div>
</template>

<script>
import Navbar from "@/components/navigation/Navbar.vue";
import LoginModal from "@/components/modals/LoginModal.vue";
import RegisterModal from "@/components/modals/RegisterModal.vue";
import RestoreModal from "@/components/modals/RestoreModal.vue";
import toast from "./mixins/toast";
import client from './mixins/client';

import PersistentMessage from "@/components/general/PersistentMessage.vue";
//import ChangePasswordModal from "@/components/modals/ChangePasswordModal.vue";

export default {
  name: "App",
  components: {
    Navbar,
    LoginModal,
    RegisterModal,
    RestoreModal,
    PersistentMessage,
    //ChangePasswordModal
  },
  mixins: [toast, client],
  data() {
    return {
      isMobile: false,
      interval: 1000 * 60 * 3,//3 minutes
      timerID: null,
      hasUpdate: false,
    };
  },
  beforeMount() {
    console.log("APP BEFORE MOUNT!");
    this.$store.dispatch("getTempToken");
  },
  mounted() {
    //---------------------------------------------//
    console.log("APP MOUNTED!");
    console.log("Begin User Check!");
    if (!this.$store.state.users.user.isLogged) {
      this.checkLogged();
      console.log(this.$store.state.users.user.isLogged);
      //console.log(this.$route.name);
    }

    this.timerID = setInterval(() => {
      if(this.$store.state.users.user.isLogged) {
        this.checkStillValid();
      }
    }, this.interval);

    //---------------------------------------------//
    if(localStorage.filterDrivers){
      console.log("Filter Drivers Found!");
      let drivers = JSON.parse(localStorage.filterDrivers);
      this.$store.commit("appdata/setData", {'type': 'filterDrivers', 'data': drivers});
    }
    //---------------------------------------------//
    //update
    //brVersion="1.0";
    setTimeout(() => {
      if(this.checkVersion()){
      console.log("Version is OK!");
      this.hasUpdate = false;
    }else{
      this.hasUpdate = true;
      console.log("Version is NOT OK!");
      //this.toast("Please refresh the page to get the latest version!", "is-warning");
    }
    }, 2500);
  },
  beforeDestroy() {
    //remove interval
    if (this.timerID) {
      clearInterval(this.timerID);
    }
  },
  methods: {
    checkStillValid() {
      if (localStorage.crcToken) {
        console.log("Validating Token..");
        console.log("Token:" + localStorage.crcToken);
        //begin post --------------------------------------------
        this.axios
          .post(`${this.$env.api}/users/validate.php`, {
            token: localStorage.crcToken,
          })
          .then((response) => {
            //console.log("RESPONCE:" + JSON.stringify(response));
            console.log(response.data);
            if (!response.data.success) {
              console.log("Not Loged!");
              this.$store.commit("users/logoutUser");
              this.$router.push({ path: "/" }).catch((err) => {
                console.log(err);
              });
              this.toast(response.data.message, "is-danger");
            }
          })
          .catch((error) => {
            //this.isLoading = false;
            console.log("token error ", error);
          });
        //end post-------------------------------------------------
      } else {
        console.log("Not Loged!");
      }
    },
    checkLogged() {
      if (localStorage.crcToken) {
        console.log("Validating Token..");
        console.log("Token:" + localStorage.crcToken);
        //begin post --------------------------------------------
        this.axios
          .post(`${this.$env.api}/users/validate.php`, {
            token: localStorage.crcToken,
          })
          .then((response) => {
            //console.log("RESPONCE:" + JSON.stringify(response));
            console.log(response.data);
            if (response.data.success) {
              this.$store.commit("users/validateUser", response.data.data);
              console.log("Loged!");
              console.log(response.data.data);
              //!!!redurect
              this.$router.push({ path: "/dashboard" }).catch((err) => {
                console.log(err);
              });
            }else{
              console.log("Not Loged!");
              localStorage.removeItem("crcToken");
              this.toast(response.data.message, "is-danger");
            }
          })
          .catch((error) => {
            //this.isLoading = false;
            console.log("token error ", error);
          });
        //end post-------------------------------------------------
      } else {
        console.log("Not Loged!");
      }
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300;700&display=swap");

@import "static/syntho/syntho-style.css";
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Set your colors
$primary: #1aa54a;
$success: #04792d;
$primary-invert: findColorInvert($primary);
$filter: #f4f4f4;

$mydark: #23262b; //#00bb8f; //00D1B2
$mylight: #ebebeb; //#00bb8f; //00D1B2

$family-primary: "Roboto", sans-serif;

$card-radius: 0.5rem;

$colors: (
  "white": (
    $white,
    $black,
  ),
  "black": (
    $black,
    $white,
  ),
  "light": (
    $light,
    $light-invert,
  ),
  "dark": (
    $dark,
    $dark-invert,
  ),
  "primary": (
    $primary,
    $primary-invert,
  ),
  "info": (
    $info,
    $info-invert,
  ),
  "success": (
    $success,
    $success-invert,
  ),
  "warning": (
    $warning,
    $warning-invert,
  ),
  "danger": (
    $danger,
    $danger-invert,
  ),
  "mydark": (
    $mydark,
    $white,
  ),
  "mylight": (
    $mylight,
    $black,
  ),
  "filter": (
    $filter,
    $white,
  ),
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

$menu-item-color: $mylight;

#app {
  background-color: $mydark;
  color: $mylight;
}

.icon.is-large {
    height: 3rem;
    width: 3rem;
    font-size: 3rem;
}

html{
  background-color: $mydark;
}


@import "~bulma";
@import "~buefy/src/scss/buefy";
</style>
